<script setup lang="ts">
import ListSkeletonLoader from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ListSkeletonLoader.vue';
import ZyroPagination from '@zyro-inc/site-modules/components/ZyroPagination.vue';
import ProductListEmptyState from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListEmptyState.vue';
import ProductListItem from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListItem.vue';
import ProductSortDropdown from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductSortDropdown.vue';
import CategoryList from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/CategoryList.vue';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH } from '@zyro-inc/site-modules/constants/defaultStyles';
import {
	getFormattedBookingDuration,
	isProductPriceRangeShown,
	getFullProductQuantity,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { getLowestPriceVariant } from '@zyro-inc/site-modules/utils/ecommerce/productUtils';
import {
	DATA_ATTRIBUTE_ANIMATION_STATE,
	DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE,
} from '@zyro-inc/site-modules/constants';
import {
	ref,
	computed,
	nextTick,
	StyleValue,
	watch,
	onMounted,
	toRef,
} from 'vue';
import {
	EcommerceCollection,
	EcommerceProduct,
	EcommerceProductSorting,
	EcommerceProductVariantQuantity,
	EcommerceVariantPrice,
	ImageHoverEffect,
	SortingOptions,
	ImageRatioOption,
} from '@zyro-inc/site-modules/types';
import { useRoute } from 'vue-router';
import { ECOMMERCE_SORTING_VALUES } from '@zyro-inc/site-modules/constants/ecommerce';

const MAX_WIDTH_CATEGORY_LIST = 200;

interface Props {
	blockId: string;
	pageCount: number;
	currentPage: number;
	products: Array<EcommerceProduct> | [];
	totalProductCount: number;
	productPages?: Record<string, any>;
	blockStyle?: Record<string, string>;
	textColorVars?: Record<string, string>;
	isProductListShown: boolean;
	isLoading?: boolean;
	isEager?: boolean;
	columnCount?: number;
	translations: Record<string, string>;
	productCategoryId?: string;
	isButtonEnabled?: boolean;
	buttonDisplay?: string;
	buttonText?: string;
	buttonStyle?: Record<string, string>;
	buttonType?: string;
	buttonBorderWidth?: number;
	ribbonStyle?: Record<string, string>;
	isProductListItemLinkDisabled?: boolean;
	siteId: string;
	variantsQuantity: Array<EcommerceProductVariantQuantity>;
	productSorting: EcommerceProductSorting;
	sorting?: typeof ECOMMERCE_SORTING_VALUES[keyof typeof ECOMMERCE_SORTING_VALUES];
	imageRatio?: ImageRatioOption;
	categories?: EcommerceCollection[];
	isCategoryListEnabled: boolean;
	backgroundColor?: string;
	isMobileView: boolean;
	imageHoverEffect?: ImageHoverEffect;
	isCategoryItemLinkDisabled?: boolean;
	productIds: string[];
	isFullWidth?: boolean;
	isTotalProductCountShown?: boolean;
	columnGap?: number;
	isListCentered?: boolean;
	isButtonFullWidth?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	isProductListShown: true,
	buttonType: 'primary',
	productPages: () => ({}),
	blockStyle: () => ({}),
	textColorVars: () => ({}),
	productCategoryId: '',
	buttonDisplay: '',
	buttonText: '',
	buttonStyle: () => ({}),
	buttonBorderWidth: 0,
	ribbonStyle: () => ({}),
	sorting: ECOMMERCE_SORTING_VALUES[SortingOptions.DEFAULT],
	productSorting: () => ({
		enabled: false,
	}),
	imageRatio: ImageRatioOption.COVER,
	isCategoryListEnabled: false,
	backgroundColor: 'var(--color-light)',
	isMobileView: false,
	imageHoverEffect: ImageHoverEffect.NO_EFFECT,
	columnGap: 24,
});

const emit = defineEmits<{
	'product-click': [EcommerceProduct],
	'button-click': [EcommerceProduct],
	'page-changed': [number],
	'sort-changed': [Event],
	'category-click': [string],
}>();

const route = useRoute();

const isMobileView = toRef(() => props.isMobileView);
const columnCountDefaultValue = computed(() => (isMobileView.value ? 1 : 3));
const columnCountValue = computed(() => props.columnCount || columnCountDefaultValue.value);
const isAnimationActive = ref(false);
const blockProductListRef = ref<HTMLElement | null>(null);
const blockListWidth = ref(0);
const resizeObserver = ref<ResizeObserver>(null as unknown as ResizeObserver);

const customAttributes = computed(() => ({
	[DATA_ATTRIBUTE_ANIMATION_STATE]: isAnimationActive.value ? DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE : null,
}));
const emptyPageMessage = computed(() => props.translations.onlineStoreNoProducts || 'No publicly visible products');

const textAlign = computed(() => props.blockStyle?.textAlign);
const imageWidth = computed(() => {
	const totalGapsWidth = (columnCountValue.value - 1) * props.columnGap;
	const categoryListWidth = props.isCategoryListEnabled ? MAX_WIDTH_CATEGORY_LIST : 0;
	const blockWidth = blockListWidth.value || ((typeof window !== 'undefined') && window?.innerWidth) || DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH;
	const contentWidth = props.isFullWidth ? blockWidth : DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH;
	const listWidth = contentWidth - totalGapsWidth - categoryListWidth;

	return Math.floor(listWidth / columnCountValue.value);
});
const gapSize = computed(() => `${props.columnGap}px`);
const defaultCategory = computed(() => ({
	id: '',
	title: props.translations.allProducts || 'All products',
}));
const currentCategoryTitle = computed(() => {
	const category = props.categories?.find(({ id }) => id === props.productCategoryId);

	return category?.title || defaultCategory.value.title;
});
const computedStyles = computed(() => objectToCssVariables(props.textColorVars) as StyleValue);
const productCountText = computed(() => (props.totalProductCount > 1
	? `${props.totalProductCount} ${props.translations.products || 'products'}`
	: `${props.totalProductCount} ${props.translations.product || 'product'}`));
const isSingleColumnCentered = computed(() => props.isListCentered && props.productIds.length === 1);
const isSortingShown = computed(() => props.productSorting.enabled && props.productSorting.sortingOptions?.length);

const handlePageChange = async (page: number) => {
	// reset animation first so it would be re-triggered on page change
	isAnimationActive.value = false;

	emit('page-changed', page);

	await nextTick();

	isAnimationActive.value = true;

	const block = document.getElementById(props.blockId);
	const blockRect = block?.getBoundingClientRect();
	const isInViewport = blockRect && blockRect.top >= 0 && blockRect.bottom <= window.innerHeight;

	if (!isInViewport) {
		block?.scrollIntoView({
			behavior: 'smooth',
		});
	}
};

const getItemProductPage = (productId: string | number) => Object.values(props.productPages).find((page) => page.productId === productId);

const getItemProductPageTo = (productId: string | number) => {
	if (props.isProductListItemLinkDisabled) {
		return route;
	}

	const productPage = getItemProductPage(productId);

	if (!productPage) {
		return {
			path: '/',
		};
	}

	return {
		path: `/${productPage.slug}`,
	};
};

const getSmallestProductPrice = (product: EcommerceProduct): EcommerceVariantPrice => (
	isProductPriceRangeShown(product) ? getLowestPriceVariant(product).prices[0] : product.variants[0].prices[0]
);

const getProductImage = (product: EcommerceProduct): string | null => {
	if (!isProductPriceRangeShown(product)) {
		return product.thumbnail;
	}

	return getLowestPriceVariant(product).image_url || product.thumbnail;
};

const getSecondaryProductImage = (product: EcommerceProduct): string | null => product.images[1]?.url;

const observeList = () => {
	if (blockProductListRef.value) {
		resizeObserver.value.observe(blockProductListRef.value);
	}
};

watch(() => props.isFullWidth, (newValue) => {
	if (newValue) {
		observeList();
	} else {
		resizeObserver.value?.disconnect();
	}
});

onMounted(() => {
	resizeObserver.value = new ResizeObserver((entries) => {
		const { width } = entries[0].contentRect;

		blockListWidth.value = width;
	});

	if (props.isFullWidth) {
		observeList();
	}
});
</script>

<template>
	<div
		:id="blockId"
		ref="blockProductListRef"
		:style="computedStyles"
		class="block-product-list"
		:class="{ 'block-product-list--with-categories': isCategoryListEnabled }"
	>
		<div
			class="block-product-list__wrapper"
			:class="{ 'block-product-list__wrapper--full-width': isFullWidth }"
		>
			<CategoryList
				v-if="!isLoading && isCategoryListEnabled"
				:translations="translations"
				:categories="categories"
				:current-category-id="productCategoryId"
				:is-mobile-view="isMobileView"
				:default-category="defaultCategory"
				:is-link-disabled="isCategoryItemLinkDisabled"
				:block-id="blockId"
				@category-click="emit('category-click', $event)"
			>
				<template #mobile>
					<ProductSortDropdown
						v-if="!isTotalProductCountShown && isSortingShown"
						:product-sorting="productSorting"
						:sorting="sorting"
						:translations="translations"
						class="product-list__sort"
						@sort-changed="emit('sort-changed', $event)"
					/>
				</template>
			</CategoryList>
			<div class="block-product-list__content-container">
				<ListSkeletonLoader
					v-if="isLoading"
					:column-count="columnCountValue"
					:image-ratio="imageRatio"
					:is-category-list-enabled="isCategoryListEnabled"
					:is-full-width="isFullWidth"
				/>
				<div
					v-else-if="isProductListShown"
					style="width: 100%;"
				>
					<h3
						v-if="isCategoryListEnabled"
						class="block-product-list__category"
					>
						{{ currentCategoryTitle }}
					</h3>
					<div
						ref="productList"
						class="product-list"
					>
						<div
							v-if="isTotalProductCountShown || isSortingShown"
							class="product-list__header"
						>
							<p
								v-if="isTotalProductCountShown"
								class="product-list__total-count"
							>
								{{ productCountText }}
							</p>
							<ProductSortDropdown
								v-if="isSortingShown"
								:product-sorting="productSorting"
								:sorting="sorting"
								:translations="translations"
								class="product-list__sort"
								:class="{ 'product-list__sort--hidden-mobile': !isTotalProductCountShown && isCategoryListEnabled }"
								@sort-changed="emit('sort-changed', $event)"
							/>
						</div>
						<div
							class="product-list__content"
							:class="{ 'product-list__content--single-column': isSingleColumnCentered }"
						>
							<RouterLink
								v-for="(product, index) in products"
								:key="`product-${index}-${product.id}`"
								:to="getItemProductPageTo(product.id)"
								class="product-list__link"
							>
								<ProductListItem
									v-bind="customAttributes"
									:image="getProductImage(product)"
									:secondary-image="getSecondaryProductImage(product)"
									:title="product.title"
									:ribbon="product.ribbon_text"
									:price="getSmallestProductPrice(product)"
									:text-align="textAlign"
									:is-eager="isEager && index === 0"
									:duration="getFormattedBookingDuration(product, translations)"
									:image-width="imageWidth"
									:image-ratio="imageRatio"
									:image-hover-effect="imageHoverEffect"
									:image-border-radius="blockStyle?.imageBorderRadius"
									:is-store-quantity-tracked="product.variants[0].manage_inventory"
									:is-price-range-shown="isProductPriceRangeShown(product)"
									:quantity="getFullProductQuantity({
										product,
										variantsQuantity
									})"
									:product-type="product.type.value"
									:translations="translations"
									:is-mobile-view="isMobileView"
									:is-button-enabled="isButtonEnabled"
									:button-display="buttonDisplay"
									:button-text="buttonText"
									:button-style="buttonStyle"
									:button-type="buttonType"
									:button-border-width="buttonBorderWidth"
									:ribbon-style="ribbonStyle"
									:is-purchasable="product.purchasable ?? true"
									:is-button-full-width="isButtonFullWidth"
									:site-id="siteId"
									@click="$emit('product-click', product)"
									@button-click="$emit('button-click', product)"
								/>
							</RouterLink>
						</div>
						<ZyroPagination
							:current-page="currentPage"
							:page-count="pageCount"
							class="product-list__pagination"
							color="var(--body-color)"
							@change-page="handlePageChange($event)"
						/>
					</div>
				</div>
				<div
					v-else
					class="block-product-list__empty-state"
				>
					<h3
						v-if="isCategoryListEnabled"
						class="block-product-list__category"
					>
						{{ currentCategoryTitle }}
					</h3>
					<ProductListEmptyState
						:text-color-vars="textColorVars"
						:empty-page-message="emptyPageMessage"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/font-style";
@include font-style("h6", "h2", ".block-product-list");
@include font-style("h3", "h3", ".block-product-list");
@include font-style("body", ".text-body", ".block-product-list");
@include font-style("body-small", ".text-body-small", ".block-product-list");

@mixin product-list-mobile() {
	.block-product-list {
		padding: var(--m-block-padding);

		&__wrapper {
			flex-direction: column;
			gap: 0;
		}

		&__category {
			display: none;
		}
	}

	.product-list {
		&__content,
		&__content--single-column {
			display: grid;
			max-width: unset;
			margin: unset;
		}

		&__sort {
			display: flex;
			justify-content: space-between;

			&--hidden-mobile {
				display: none;
			}
		}

		&__header {
			margin-bottom: 16px;
			gap: 8px;
		}

		&__total-count {
			color: var(--body-m-color, var(--body-color));
			flex-grow: 0;
			margin-right: 4px;
			font-size: 14px;
		}

		&__pagination {
			margin-top: 16px;

			:deep(.pagination__trigger--current),
			:deep(button:hover),
			:deep(button:focus) {
				color: var(--body-m-color, var(--body-color));
			}
		}
	}
}

.block-product-list {
	z-index: $z-index-site-engine-block-grid;
	padding: var(--block-padding);

	&--with-categories {
		.product-list-container {
			justify-content: flex-start;
		}
	}

	&__wrapper {
		max-width: var(--content-width);
		display: flex;
		width: 100%;
		margin: 0 auto;
		gap: 40px;

		&--full-width {
			max-width: unset;
		}
	}

	&__content-container {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	&__category {
		margin-bottom: 32px;
		word-break: break-all;
	}

	&__empty-state {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}

.product-list {
	$this: &;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	&__header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 24px;
	}

	&__total-count {
		flex: 1 0 auto;
		color: var(--body-color);
		font-size: 14px;

		& ~ #{$this}__sort {
			justify-content: flex-end;
		}
	}

	&__link {
		width: 100%;
		text-decoration: none;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(v-bind(columnCountValue), 1fr);
		column-gap: v-bind(gapSize);
		width: 100%;
		row-gap: v-bind(gapSize);

		&--single-column {
			display: flex;
			max-width: calc(v-bind(imageWidth) * 1px);
			margin: 0 auto;
		}
	}

	&__pagination {
		margin-top: 16px;

		:deep(.pagination__trigger--current),
		:deep(button:hover),
		:deep(button:focus) {
			color: var(--body-color);
			text-decoration: underline;
			filter: brightness(0.8)
		}
	}
}

@include site-engine-mobile {
	@include product-list-mobile;
}

@media screen and (min-width: 601px) and (max-width: $media-mobile) {
	@include product-list-mobile;

	.product-list {
		&__content,
		&__content--single-column {
			grid-template-columns: repeat(2, 1fr);
		}

		&__sort {
			justify-content: flex-end;
		}
	}
}
</style>
